import { render, staticRenderFns } from "./SupplierCard.vue?vue&type=template&id=89a90464&scoped=true&"
import script from "./SupplierCard.vue?vue&type=script&lang=js&"
export * from "./SupplierCard.vue?vue&type=script&lang=js&"
import style0 from "./SupplierCard.vue?vue&type=style&index=0&id=89a90464&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "89a90464",
  null
  
)

export default component.exports