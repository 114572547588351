<template>
  <v-card 
    class="supplier-card"
  >    
    <div 
      class="img-block d-flex align-items-center justify-content-center" 
      :style="{ background: bgColor }"
    >
      <img :src="logo" alt="" class="h-100 w-100" v-if="isFull">
      <img :src="logo" alt="" class="supplier-img" v-else>
    </div> 
    <div 
      :style="{ background: titleBgColor, color: fontColor }"
      class="text-center py-2 px-1 w-100 font-weight-bold text-truncate fs16"
    >
      {{ text }}
    </div>  
  </v-card>
</template>

<script>
export default {
  name: "SupplierCard",
  components: {
  },
  props: {
    text: String, 
    logo: String,
    bgColor: String,
    titleBgColor: String,
    fontColor: String,
    isFull: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
  .supplier-card {
    width: 127px;
    margin: 10px;
    @media screen and (max-width: 480px)  {
      // width: 30%;
      margin: 5px;
    }
    .img-block {
      height: 100px;
    }
    .supplier-img {
      width: 80%;
      max-height: 80%;
      object-fit: contain;

    }
  }
</style>